@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lora&display=swap');

.inter-font {
    font-family: 'Inter', sans-serif !important;
}

.toast-container {
    position: fixed !important;
}

.addProduct-Modal {
    max-width: 50%;
    height: 800px;
}

table.table td, table.table th {
    padding: 0.3rem;
    vertical-align: center;
}

table.table tr {
    vertical-align: middle;
}

.nav-link-custom {
    /* font-size: 1.2rem !important; */
    text-decoration: none;
    height: 40px !important;
    /* height: 24px; */
    display: block;
    color: #464646;
    /* margin: 0px 0 0 0; */
}
.nav-link-custom:hover {
    text-decoration: underline;
    text-decoration-color: #464646;
    color: #464646;
    /* text-decoration-thickness: 2px; */
}